import {Card} from "./Card";

var template = require('./strategic_budget_card.twig');

export class StrategicBudgetCard extends Card {
    constructor() {
        super();
        this.budget_config = (typeof app.appData.layout.meeting_request.budget_card == "undefined") ? "undefined" : app.appData.layout.meeting_request.budget_card;
        this.name  =  (typeof this.budget_config.label != "undefined" ) ? this.budget_config.label : "Select Strategic Account Manager";
        this.rep_required = (typeof this.budget_config.rep_required != "undefined" ) ? this.budget_config.rep_required : true;
        this.enable_usertype4 = (typeof this.budget_config.enable_usertype4 != "undefined" ) ? this.budget_config.enable_usertype4 : true;
        this.dom = $(twig({data: template}).render({conf: {name : this.name,rep_required:this.rep_required}}));
        this.manager_autocomplete = this.dom.find('#manager_autocomplete');
        this.budget_available = this.dom.find('#budget_available');
        this.card_message = this.dom.find("#sf_budget_error");
        this.dom.find('#strategic_budget_card').hide();

        this.dom.on("autocompleteselect", (event,ui) => {
            this.card_message.hide();
            this.manager_autocomplete.val(ui.item.label);
            this.budget_available
                .data('position-id',ui.item.value)
                .data('budget-rdt','')
                .data('rdt','');
            this.isbudgetremaining();
            return false;
        })

        this.dom.on("click", "#manager_autocomplete", () => {
            this.manager_autocomplete.autocomplete( "search")
        })

        this.dom.on("autocompleteclose", () => {
            var instance = this.manager_autocomplete.autocomplete("instance");
            if(instance.term == "")
            {
                this.card_message.hide();
                this.budget_available
                    .data('position-id','')
                    .data('budget-rdt','')
                    .data('rdt','');         
                if(this.rep_required ) {
                    this.manager_autocomplete.addClass('is-invalid');
                    this.dom.trigger('CardNotFilledOut');
                }
                this.dom.trigger('NeedBudgetUpdate');
            }
        })

        this.managerPositionData = {}
    }

    checkConfig()
    {
        let check = false;
        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();

        if(project_id != undefined && program_type_id != undefined) 
        {
            let meetingRequestConfig = app.appData.projects[project_id].program_types[program_type_id].configuration.sb.meeting_request;
            let show_card = meetingRequestConfig ?.Check_budget_by_Sales_Force_selection ?? false;

            let defaultMeetingRequestConfig = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request;
            let default_show_card = defaultMeetingRequestConfig?.Check_budget_by_Sales_Force_selection ?? false;

            let check_budget = app.appData.projects[project_id].check_budget == "1";

            if((show_card ? show_card : default_show_card) && check_budget) {
                check = true;
            }
        }

        return check;
    }

    canShowNextCard()
    {
        let can_show = true;
        if(this.budget_available.data('budget-rdt') == '' && this.checkConfig()) {
            can_show = false;
        }

        if(!this.rep_required ) {
            can_show = true;
        }
        return can_show;
    }

    show() {
        if(this.checkConfig()) {
            this.update();
            super.show();
        } else {
            this.manager_autocomplete.removeClass('invalid, required')
            this.cardFilledOutTriggers();
        }
    }

    update() {
        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();

        if(project_id != undefined && program_type_id != undefined)
        {
            return $.ajax({
                url: 'api/meetingrequest/getAccountManagers',
                method: 'get',
                data: {
                    project_id : $('#project_dd').val(),
                    rdt_prefix : app.appData.projects[project_id].program_types[program_type_id].configuration.sb.meeting_request?.Sales_Force_selection_rdt_prefix ?? ''
                }
            }).then((data) => {
                this.managerPositionData = data;
                this.drawStrategicBdugetDD();
            })
        }

        return false;
    }

    reset() {
        this.managerPositionData = {}
        this.budget_available
            .data('position-id','')
            .data('budget-rdt','')
            .data('rdt','');
        this.drawStrategicBdugetDD();
    }

    drawStrategicBdugetDD() {
        if(Object.keys(this.managerPositionData).length > 0)
        {
            this.dom.find('#strategic_budget_card').show();
            this.manager_autocomplete.autocomplete({
                source: this.managerPositionData,
                minLength: 0
            })
        }
        else
        {
            this.dom.trigger('CardFilledOut');
            this.dom.find('#strategic_budget_card').hide();
        }
    }

    isbudgetremaining() 
    {
        return $.ajax({
            url: 'api/meetingrequest/getBudgetRemaining',
            method: 'get',
            data: {
                project_id : $('#project_dd').val(),
                sf_budget_position : this.budget_available.data("position-id"),
                enable_usertype4 : this.enable_usertype4
            }
        }).then((data) => {
            this.budget_available
                .data('budget-rdt',data.budget_rdt)
                .data('rdt',data.rdt);
            this.dom.trigger('NeedBudgetUpdate');
            if(!data.status) {
                this.card_message.show();
                this.dom.trigger('CardNotFilledOut');
                this.manager_autocomplete.addClass('is-invalid');
            } else {
                this.manager_autocomplete.removeClass('is-invalid');
                this.dom.trigger('CardFilledOut');
            }
        })   
    }
}
