import { Page } from './page.js';
import { Dropdown } from '../components/Dropdown';
import { SpeakerNominateForm } from "./speakernominate/SpeakerNominateForm";
import { SpeakerNominateLog } from "./speakernominate/SpeakerNominateLog";
import { ModalAddNote } from "./speakernominate/ModalAddNote";
import { ModalReviewQuestions } from "./speakernominate/ModalReviewQuestions";
import { ModalUpload } from "./speakernominate/ModalUpload";
import { SpeakerNominateStatusLegend } from '../components/SpeakerNominateStatusLegend';
import { ModalApproval } from "./speakernominate/ModalApproval";

let template = require('./speakernominate/speakernominate.twig');
let field_template = require('../components/speaker_nominate_filter_fields.twig');

export class SpeakerNominate extends Page {
    constructor() {
        super();
        this.tableColumns = [];
        this.exportColumns = [];
        this.containers = [];
        this.manual_reset_fields = [];
        let filters1 = app.appData.layout.speaker_nominate.filters1;
        let filters2 = app.appData.layout.speaker_nominate.filters2;

        this.dom = $(twig({ data: template }).render({
            columns: app.appData.layout.speaker_nominate.list,
            labels: app.appData.labels
        }));

        filters1.forEach((filter) => {
            if(!this.containers[filter.name]) {
                this.containers[filter.name] = $(twig({data: field_template}).render({filter: filter}));
            }
            if(filter.type == 'dropdown') {
                this.renderFiltersDD(filter);
            }

            this.dom.find('#speaker_search_filter_container').append(this.containers[filter.name]);
        })

        filters2.forEach((filter) => {
            if(!this.containers[filter.name]) {
                this.containers[filter.name] = $(twig({data: field_template}).render({filter: filter}));
            }
            if(filter.type == 'dropdown') {
                this.renderFiltersDD(filter);
            }

            this.dom.find('#speaker_nominate_filter_container').append(this.containers[filter.name]);
        })

        if(filters2.length == 0)
        {
            this.dom.find("#speaker_nominate_filter").hide();
        }

		this.dom.find('#reset_filter').on('click', (event) => {
            event.preventDefault();
            this.dom.find('#speaker_search_filter_form')[0].reset();
            this.dom.find('#datatable_speakers_wrapper').hide();
            this.dom.find('.nominate_speaker_via_form_button').hide();
            this.canSearch();
		});

        this.dom.find('#reset').on('click', (event) => {
            event.preventDefault();
            this.dom.find('#speaker_nominate_filter_form').trigger('reset').trigger('submit');
            this.manual_reset_fields.forEach(filter => {
				$("[name='"+filter.real_field+"']").val(null).trigger('change')
			})
		});

        this.dom.on('change', 'select.required', () => {
            this.canSearch();
        })

        this.dom.on('keyup', 'input.required', () => {
            this.canSearch();
        })

        this.dom.on('change', '#renominate_speaker', () => {
            this.canSearch();
            if(this.dom.find('#renominate_speaker').is(':checked')){
                this.dom.find('#my_speakers_only-container').show();
                this.dom.find('#my_speakers_only').prop('checked', true);

            }
            else{
                this.dom.find('#my_speakers_only-container').hide();
                this.dom.find('#my_speakers_only').prop('checked', false);
            }
        })

        this.dom.find('.nominate_speaker_via_form_button').on('click', (event) => {
            event.preventDefault();
            this.speakerNominateModal({}, "add_via_form");
		});
    }

    canSearch() {
        let result = true;
        this.dom.find('#search_speaker').prop('disabled', true);
        this.dom.find('#reset_filter').prop('disabled', true);

        this.dom.find("#speaker_search_filter_form .required").each((index, elem) => {
            if($(elem).val() ||
                (this.dom.find('#renominate_speaker').is(':checked') && elem.id != 'brand')) {
                $(elem).removeClass('is-invalid');
            }
            else {
                result = false;
                $(elem).addClass('is-invalid');
                this.dom.find('#datatable_speakers_wrapper').hide();
            }
        })
        if(result) {
            this.dom.find('#search_speaker').prop('disabled', false)
            this.dom.find('#reset_filter').prop('disabled', false)
        }
    }

    renderFiltersDD(filter) {
        filter.real_field = filter.name
		if (filter.multiple) {
			filter.real_field = filter.name + "[]"
			this.manual_reset_fields.push(filter);
		}
		switch (filter.name) {
			case 'state' :
				this.drawStateDD(filter)
				break;
            case 'brand' :
            case 'brand_id' :
				this.drawBrandDD(filter)
				break;
            case 'status' :
                this.drawSpeakerStatusDD(filter)
                break;
            case 'tll' :
                this.drawTllDD(filter)
                break;
		}
	}

    drawStateDD(filter) {
        this.containers[filter.name].html('');
        let stteDD = new Dropdown(app.appData.states)
        stteDD
           .label(filter.label)
           .all(true)
           .multiple(filter.multiple)
           .draw(this.containers[filter.name], 'alpha2', 'name', filter.real_field)
    }

    drawBrandDD(filter) {
        $.ajax({
            url: 'api/speakernominate/brands'
        }).then((data) => {
            this.containers[filter.name].html('');
            let brandDD = new Dropdown(data);
            brandDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .addClass(filter.required == 1 ? 'required is-invalid' : '')
                .draw(
                    this.containers[filter.name],
                    'brand_id', 'brand_name',
                    filter.real_field, ['prev_brand','pre_approval_required']
                )
        })
    }

    drawSpeakerStatusDD(filter) {
        $.ajax({
            url: 'api/speakernominate/status'
        }).then((data) => {
            this.containers[filter.name].html('');
            let speakerStatusDD = new Dropdown(data);
            speakerStatusDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .addClass(filter.required == 1 ? 'required is-invalid' : '')
                .draw(this.containers[filter.name], 'speaker_status_id', 'speaker_status_name', filter.real_field)
        })
    }

    drawTllDD(filter) {
        $.ajax({
            url: 'api/speakernominate/tll'
        }).then((data) => {
            this.containers[filter.name].html('');
            let tllDD = new Dropdown(data);
            tllDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .addClass(filter.required == 1 ? 'required is-invalid' : '')
                .draw(this.containers[filter.name], 'wwid', 'sfm_name', filter.real_field)
        })
    }

    setTableColumns(table, columns) {
        this.tableColumns = [];
        this.exportColumns = [];

        $('#'+table +' thead tr').children('th').each((k, elem) => {
            let key = $(elem).data('key');
            let column = columns.find(({ columnKey }) => columnKey == key);
            let approveLabel = "Approve";
            let deny = '<button type="button" class="btn btn-outline-danger ms-1 deny">Deny</button>';
            let visible = true;
            let className = 'row-speaker';
            if(key == 'speaker_checkbox') {
                className = '';
            }

            if(app.appData.layout.speaker_nominate.review.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
                approveLabel = "Reviewed";
            }

            if(app.appData.layout.speaker_nominate.hide_deny.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
                deny = "";
            }

            if(typeof column !== 'undefined'){
                if(column?.export ?? false) {
                    this.exportColumns.push(this.tableColumns.length);
                }

                this.tableColumns.push({
                    data: key,
                    visible: visible,
                    class: className,
                    orderable: column?.orderable ?? false,
                    render : (data, type, row) => {
                        let html = '<span>' + (data || '') + '</span>';
                        if(key == "can_nominate" && data == '1') {
                            html = '<button type="button" class="btn btn-primary nominate">Nominate</button>';
                        }

                        if(key == "cv") {
                            html = "";
                            if(row.file_id != null) {
                                html += '<button class="downloadFile btn btn-info"><i class="fas fa-file-download fa-lg"></i></button>';
                            }
                            if(app.appData.layout.speaker_nominate.upload_cv) {
                                html += ' <button type="button" class="btn btn-primary upload">Upload</button>';
                            }
                        }

                        if(key == "scorecard") {
                            html = "";
                            if(row.sc_file_id != null) {
                                html += '<button class="downloadFile btn btn-info downloadSc"><i class="fas fa-file-download fa-lg"></i></button>';
                            }
                            html += ' <button type="button" class="btn btn-primary upload_sc">Upload</button>';
                        }

                        if(key == "can_approve_deny" && parseInt(data) > 0) {
                            html = '<button type="button" class="btn btn-outline-success approve">' + approveLabel + '</button>' + deny;
                        }

                        if(key == "log") {
                            html = '<button type="button" class="btn btn-primary log">View</button>';
                        }

                        if(key == "speaker_checkbox") {
                            html = parseInt(row.can_approve_deny) > 0 ? '<input type="checkbox" class="speaker-selection" />' : ''
                        }

                        return html;
                    }
                });
            }
        });
    }

    initializeTable(data) {
        this.setTableColumns("datatable_speaker_search_results", app.appData.layout.speaker_nominate.list.search_speaker_list);
        this.speakersTable = $('#datatable_speaker_search_results').DataTable({
            "data": data,
            "processing": true,
            "paging": true,
            "lengthChange": true,
            "searching": true,
            "ordering": true,
            "deferRender": true,
            "pagingType": $(window).width() < 768 ? "simple" : "full_numbers",
            "pageLength": $(window).width() < 768 ? 2 : 10,
            "destroy": true,
            "language": {
                "emptyTable": "No speakers to display",
                "search": "",
                "searchPlaceholder": "Search...",
                "processing": "Loading..."
            },
            "buttons": [
                {
                    'extend' : 'csvHtml5',
                        'title': "Nominate_Speakers",
                        'className':'btn btn-success export',
                        'text': '<i class="fas fa-file-excel"></i>',
                        'exportOptions': {
                        'orthogonal' : 'export',
                        'columns' : this.exportColumns
                    }
                }
            ],
            "dom": '<"row g-0"<"col-md-6"l><"col-md-6"<"row"<"col-md-6"f><"col-md-6"B>>>><t>rip',
            "columns": this.tableColumns,
            "drawCallback": () => {
                app.convertDatatableToCard("datatable_speaker_search_results");
                let dataTable = this.dom.find('#datatable_speaker_search_results').DataTable();

                this.dom.find('.nominate').on('click', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    this.speakerNominateModal(info, "");
                })
            }
        });

        this.setTableColumns("datatable_speaker_nominate", app.appData.layout.speaker_nominate.list.nominated_speaker_list);
        this.nominatedSpeakersTable = $('#datatable_speaker_nominate').DataTable({
            "processing": true,
            "paging": $(window).width() < 768 ? false : true,
            "lengthChange": true,
            "searching": true,
            "ordering": true,
            "deferRender": true,
            "pagingType": "full_numbers",
            "pageLength": 10,
            "destroy": true,
            "language": {
                "emptyTable": "No speakers to display",
                "search": "",
                "searchPlaceholder": "Search...",
                "processing": "Loading..."
            },
            "dom": '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-md-6"f><"col-md-6 text-end"<"w-100 float-start"B>>>>><t>ip',
            "ajax": {
                "url": "api/speakernominate"
            },
            "columns": this.tableColumns,
            "buttons": [{
                    'className':'btn btn-success approve-all',
                    'text': 'Approve All'
                },
                {
                    'className':'btn btn-danger deny-all',
                    'text': 'Deny All'
                },
                'colvis',
                {
                    'extend' : 'csvHtml5',
                    'title': "Nominated_Speakers",
                    'className':'btn btn-success export',
                    'text': '<i class="fas fa-file-excel"></i>',
                    'exportOptions': {
                        'orthogonal' : 'export',
                        'columns' : this.exportColumns
                    }
                },
                {
                    'className':'btn btn-dark status-legend',
                    'text': '<i class="fas fa-info-circle"></i>'
                }
            ],
            "createdRow": (row, data) => {
                if(data.speaker_status_key == 'nomin_approved') {
                    $(row).addClass('alert alert-success');
                }
                else if(data.speaker_status_key == 'nomin_rejected') {
                    $(row).addClass('alert alert-danger');
                }
            },
            "drawCallback": () => {
                app.convertDatatableToCard("datatable_speaker_nominate");
                let dataTable = this.dom.find('#datatable_speaker_nominate').DataTable();

                this.dom.find("#datatable_speaker_nominate tbody").off('click')
                .on('change', '.speaker-selection', (e) => {
                    e.preventDefault();
                    let row = $(e.target).closest('tr')
                    if($(e.target).is(':checked')) {
                        row.addClass('selected')
                    } else {
                        row.removeClass('selected')
                        row.find('.approve, .deny').prop('disabled', false).removeAttr('title')
                    }

                    if(dataTable.rows('.selected').count() > 1) {
                        this.dom.find('tr.selected .approve, tr.selected .deny').prop('disabled', true).attr('title', 'To approve/deny an individual speaker, uncheck MDM_ID')
                        this.dom.find('.approve-all, .deny-all').prop('disabled', false)
                    }
                    else {
                        this.dom.find('tr .approve, tr .deny').prop('disabled', false).removeAttr('title')
                        this.dom.find('.approve-all, .deny-all').prop('disabled', true)
                    }

                    return false; // Do not trigger next event handler
                })
                .on('click', '.downloadFile', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let fileId = info.file_id;
                    if($(e.target).hasClass('downloadSc')) {
                        fileId = info.sc_file_id
                    }
                    window.open("api/speakers/"+info.speaker_id+"/download/"+fileId);
                    return false; // Do not trigger next event handler
                })
                .on('click', '.log', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    this.speakerNominateLog = new SpeakerNominateLog(info.speaker_id, info.brand_id);
                    return false; // Do not trigger next event handler
                })
                .on('click', '.approve', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let data = {
                        'speaker_id': info.speaker_id,
                        'status_id': info.status_id,
                        'brand_id': info.brand_id,
                        'action': 'approve'
                    }

                    if(app.appData.layout.speaker_nominate.additional_review.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
                        this.modalReviewQuestions = new ModalReviewQuestions(data);
                    } else {
                        $.ajax({
                            url: "api/speakernominate/changestatus",
                            method: "POST",
                            data: data
                        }).then(response => {
                            if(response.success) {
                                displayNotification({
                                    title: 'Speaker nomination approved',
                                    success: true
                                });
                                this.nominatedSpeakersTable.ajax.reload(null, false);
                            }
                            else {
                                displayNotification({
                                    title: 'Speaker nomination approval failed',
                                    success: false
                                });
                            }
                        })
                    }
                    return false; // Do not trigger next event handler
                })
                .on('click', '.deny', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let data = {
                        'speaker_id': info.speaker_id,
                        'status_id': info.status_id,
                        'brand_id': info.brand_id,
                        'action': 'deny'
                    }
                    this.modalAddNote.setData(data);
                    // Set the reload callback for the modal
                    this.modalAddNote.setReloadCallback(() => {
                        // Reload DataTable after the modal is hidden
                        this.nominatedSpeakersTable.ajax.reload(null, false);
                    });
                    this.modalAddNote.modal.show();
                    return false; // Do not trigger next event handler
                })
                .on('click', '.upload', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let data = {
                        'speaker_id': info.speaker_id,
                        'file_type': 'upload_cv',
                        'name': 'CV'
                    }
                    this.modalUploadCv = new ModalUpload(data);
                    this.modalUploadCv.setReloadCallback(() => {
                        // Reload DataTable after the modal is hidden
                        this.nominatedSpeakersTable.ajax.reload(null, false);
                    });
                    this.modalUploadCv.modal.show();
                    return false;
                })
                .on('click', '.upload_sc', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let data = {
                        'speaker_id': info.speaker_id,
                        'file_type': 'upload_scorecard',
                        'name': 'Scorecard'
                    }
                    this.modalUploadSc = new ModalUpload(data);
                    this.modalUploadSc.modal.show();
                    return false;
                })
                .on('click', '.row-speaker', (e) => {
                    e.preventDefault();
                    let info = dataTable.row($(e.target).parents('tr')).data();
                    let data = {
                        'speaker_id': info.speaker_id,
                        'brand_id': info.brand_id,
                        'brand_ids': info.brand_ids
                    }
                    $.ajax({
                        url: "api/speakernominate/speaker",
                        method: "GET",
                        data: data
                    }).then(response => {
                        response.speaker_id = info.speaker_id;
                        this.speakerNominateForm.editForm = true;
                        this.speakerNominateForm.resetExtraEvals()
                        this.speakerNominateForm.setFormVals(response);
                        //show speaker info button
                        this.speakerNominateForm.dom.find('#speaker_info_btn').show();
                        this.speakerNominateForm.modal.show();

                        if(app.appData.layout.speaker_nominate.display_eval) {
                            this.speakerNominateForm.fetch_evaluation_html(info.speaker_id, info.brand_id);
                        }

                        if (response.enable_update_functionality_for_user_types.includes(String(app.appData.curPosition.user_type_id))) {
                            this.dom.find('.modal-footer').show();
                            this.dom.find('#upload_cv').attr("required", false).removeClass("required");
                            this.dom.find('#submit_spk_nomination').addClass("update").prop('disabled', false);
                            app.appData.updated_field_for_notification = response.updated_field_for_notification
                        } else {
                            this.dom.find('.modal-footer').hide();
                        }

                        this.dom.find('#cv').hide();
                    })
                });

                $('.status-legend').first().on('click', () => {
                    this.speakerNominateStatusLegend.modal.show();
                })
            }
        });
        $('.export').attr('title', 'Click to download');
        $('.status-legend').attr('title', 'Click to view Status Legend');
        $('.approve-all, .deny-all').prop('disabled', true).hide();
        if(app.appData.layout.speaker_nominate.approve_deny_all.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
            $('.approve-all, .deny-all').show();
        }

        this.dom.find('.speaker_checkbox_all').on('change', (event) => {
            this.dom.find('.speaker-selection').prop('checked', $(event.target).is(':checked')).trigger('change')
        })

        $('.approve-all').on('click', (event) => {
            event.preventDefault();
            let speakers = this.dom.find('#datatable_speaker_nominate').DataTable().rows('.selected').data().toArray()
            let info = [];
            $.each(speakers, (key, speaker) => {
                info.push({'speaker_id': speaker.speaker_id, 'brand_id': speaker.brand_id, 'status_id': speaker.status_id});
            });
            let data = {
                'action': 'approve',
                'speakers': info
            }
            this.modalApproval = new ModalApproval(data);
            this.modalApproval.modal.show();
        })

        $('.deny-all').on('click', (event) => {
            event.preventDefault();
            let speakers = this.dom.find('#datatable_speaker_nominate').DataTable().rows('.selected').data().toArray()
            let info = [];
            $.each(speakers, (key, speaker) => {
                info.push({'speaker_id': speaker.speaker_id, 'brand_id': speaker.brand_id, 'status_id': speaker.status_id});
            });
            let data = {
                'action': 'deny',
                'speakers': info
            }
            this.modalApproval = new ModalApproval(data);
            this.modalApproval.modal.show();
        })
    }

    checkCap() {
        return new Promise((resolve, reject) => {
            if(app.appData.layout.speaker_nominate.cap_check) {
                let data = {
                    'rdt': app.appData.curPosition.rdt,
                    'wwid': app.appData.curPosition.wwid,
                    'brand_id': $('#brand').val()
                }
                $.ajax({
                    url: "api/speakernominate/checkcap",
                    method: "GET",
                    data: data
                }).then(response => {
                    if(response.success) {
                        resolve(true);
                    }
                    else {
                        displayNotification({
                            title: response.message,
                            success: false
                        });
                        reject(false);
                    }
                })
            }
            else {
                resolve(true)
            }
        })
    }


    speakerNominateModal(info, action="") {
        let data = {};
        let response = {};
        let renominate = 0;
        let fields = app.appData.layout.speaker_nominate.form_fields;

        this.checkCap()
        .then(() => {
            if(action == "add_via_form") {
                response.speaker_id = 0;
                response.renominate = 0;
                response.region2 = app.appData.curPosition.region_name;
                if(fields["sf_district"] != undefined) {
                    response.region1 = app.appData.curPosition.district_name;
                }

                this.speakerNominateForm.editForm = false;
                this.speakerNominateForm.resetExtraEvals()
                this.speakerNominateForm.setFormVals(response);
                if(app.appData.layout.speaker_nominate.display_eval) {
                    this.speakerNominateForm.fetch_evaluation_html(info.speaker_id, info.brand_id);
                }

                //hide info button
                this.speakerNominateForm.dom.find('#speaker_info_btn').hide();
                this.speakerNominateForm.modal.show();
                this.dom.find('.modal-footer').show();
                this.dom.find('#cv').show();
                this.dom.find('#submit_spk_nomination').removeClass("update");
                return false;
            }
            else if(info.speaker_id) {
                action = 'speaker';
                data = {
                    'speaker_id': info.speaker_id,
                    'prev_brand': $('#brand option:selected').data('prev_brand'),
                    'brand_id': $('#brand').val(),
                    'brand_ids': info.prev_brand_ids
                }
                renominate = 1;
            }
            else {
                action = 'target';
                data = {
                    'target_id': info.target_id,
                    'address_id': info.address_id
                }
            }

            $.ajax({
                url: "api/speakernominate/"+action,
                method: "GET",
                data: data
            }).then(response => {
                response.speaker_id = info.speaker_id;
                response.renominate = renominate;
                if(!response.speaker_id)
                {
                    response.region2 = app.appData.curPosition.region_name;
                }

                if(fields["sf_district"] != undefined) {
                    response.region1 = app.appData.curPosition.district_name;
                }

                this.speakerNominateForm.editForm = false;
                this.speakerNominateForm.resetExtraEvals()
                this.speakerNominateForm.setFormVals(response);
                if(app.appData.layout.speaker_nominate.display_eval) {
                    this.speakerNominateForm.fetch_evaluation_html(info.speaker_id, info.brand_id);
                }

                //hide speaker info button
                this.speakerNominateForm.dom.find('#speaker_info_btn').hide();
                this.speakerNominateForm.modal.show();
                this.dom.find('.modal-footer').show();
                this.dom.find('#cv').show();
            })
        })
    }

    refreshTable(data) {
        this.speakersTable.clear();
        this.speakersTable.rows.add(data);
        this.speakersTable.columns.adjust().draw();
    }

    searchSpeakers() {
        let url = 'api/speakernominate/searchtarget';
        let data = $('#speaker_search_filter_form').serializeArray();
        data.push({name: 'prev_brand', value: $('#brand option:selected').data('prev_brand')});
        if(this.dom.find('#speaker_search_filter_form #renominate_speaker').is(':checked')) {
            url = 'api/speakernominate/searchspeaker';
        }
        return $.ajax({
            url: url,
            data: data
        });
    }

    ready() {
        $('#speaker_search_wrapper').hide();
        if(app.appData.speakerNominationRoute.nominate_status_id != undefined
            && app.appData.speakerNominationRoute.nominate_status_id != 0
            && app.appData.speakerNominationRoute.nominate_status_id != '') {
            $('#speaker_search_wrapper').show();

            this.canSearch();
            this.dom.find('#datatable_speakers_wrapper').hide();
            if(this.dom.find('#search_speaker').prop('disabled') == false) {
                this.dom.find('#search_speaker').trigger('click');
            }

            $('#speaker_search_filter_form').first().off('submit').on('submit', (e) => {
                e.preventDefault();
                this.dom.find('.page-loading-overlay').show();
                this.dom.find('#datatable_speakers_wrapper').hide();
                this.dom.find('#search_speaker').prop('disabled', true)
                this.dom.find('#reset_filter').prop('disabled', true)
                this.searchSpeakers()
                .then(response => {
                    this.refreshTable(response.data);
                    this.dom.find('.page-loading-overlay').hide();
                    this.dom.find('#datatable_speakers_wrapper').show();
                    this.dom.find('#search_speaker').prop('disabled', false)
                    this.dom.find('#reset_filter').prop('disabled', false)
                    if(app.appData.layout.speaker_nominate.show_add_form) {
                        this.dom.find('.nominate_speaker_via_form_button').show();
                    }
                })
            })
        }

        $('#speaker_nominate_filter_form').first().off('submit').on('submit', (e) => {
            e.preventDefault();
            $.ajax({
                url: 'api/speakernominate',
                data: $('#speaker_nominate_filter_form').serializeArray()
            }).then(response => {
                this.nominatedSpeakersTable.clear();
                this.nominatedSpeakersTable.rows.add(response.data);
                this.nominatedSpeakersTable.columns.adjust().draw();
            })
        })

        this.initializeTable();

        this.speakerNominateForm = new SpeakerNominateForm();
        this.modalAddNote = new ModalAddNote();
        this.speakerNominateStatusLegend = new SpeakerNominateStatusLegend();
    }
}
