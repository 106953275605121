var speaker_nominate_form_modal = require('./speaker_nominate_form_modal.twig');

import { Dropdown } from '../../components/Dropdown'
import eval_template from '../../../../node_modules/@appdev/evaluations-ui/evaluations/evaluation.twig';
import { GoogleAddressAutocomplete } from "../../helpers/GoogleAddressAutocomplete";
const nomination_types_key = 'nomination_types';

export class SpeakerNominateForm
{
    constructor() {
        this.splittableFields = [
            "degree",
            "specialty"
        ];

        this.preApprovalFields = app.appData.layout.speaker_nominate.pre_approval_fields || {};
        this.fields = Object.assign(app.appData.layout.speaker_nominate.form_fields, this.preApprovalFields);
        this.form_type = app.appData.layout.speaker_nominate.form_type;
        this.editForm = false;
        this.showMedicalFields = app.appData.configs.SPEAKER_MEDICAL_AFFILIATION || false;
        this.seletedState = ''
        this.completed_program_check = false;

        //Check used for biogen speaker nominations #50325
        if (typeof app.appData.layout.speaker_nominate.completed_program_check !== "undefined") {
            this.completed_program_check = app.appData.layout.speaker_nominate.completed_program_check;
        }

        if(this.fields.nomination_types && this.fields.nomination_types.nomination_types.length > 0) {
          for (let i = 0; i <
          this.fields.nomination_types.nomination_types.length; i++) {
            let nomination_type = this.fields.nomination_types.nomination_types[i];
            this.fields.nomination_types.form_eval_ids[nomination_type.nomination_type].nomination_type_id = nomination_type.nomination_type_id;
          }
        }

        if(this.fields.nomination_types?.form_eval_ids != undefined) {
            this.fields.nomination_types.form_eval_ids = Object.fromEntries(
                Object.entries(this.fields.nomination_types.form_eval_ids).sort(
                    (left, right) => left[0].localeCompare(right[0])
                )
            )
        }

        this.dom = $(twig({ data: speaker_nominate_form_modal }).render({
            fields: this.fields,
            labels: app.appData.labels,
            type: this.form_type,
            showMedicalFields: this.showMedicalFields
        }));

        this.setupDropdowns();
        this.setRequiredFields();
        this.dom.find('.phone').mask('(999) 999-9999');

        this.dom.find('.file_upload').on('change', (e) => {
            this.dom.find(e.target).closest('div').find(".chosen_file_name").text($(e.target)[0]['files'][0]["name"]);
        });


        this.dom.find('#medical_affiliation_country').on('change', () => {
            let countryCode = $('#medical_affiliation_country').val();
            this.buildStateDD(this.dom.find("#medical-state-dd-container"), 'medical_affiliation_state', countryCode)
        });

        this.dom.find('#practice_setting').on('change', () => {
            const practiceSetting = this.dom.find('#practice_setting option:selected').text()
            if (practiceSetting.toLowerCase() == 'other') {
                this.dom.find(".practice_setting_other_container").show()
            }  else {
                this.dom.find("#practice_setting_other").val('')
                this.dom.find(".practice_setting_other_container").hide()
            }
        })

        this.dom.find('#medical_affiliation_ques').on('change', (event) => {
            if ($(event.target).val() == '1') {
                this.dom.find('.medical_affiliation').show()
                this.dom.find('#medical_affiliation_name').addClass('required is-invalid').attr('required')
                this.dom.find('#medical_affiliation_address1').addClass('required is-invalid').attr('required')
                this.dom.find('#medical_affiliation_city').addClass('required is-invalid').attr('required')
                this.dom.find('#medical_affiliation_state').addClass('required is-invalid').attr('required')
                this.dom.find('#medical_affiliation_zip').addClass('required is-invalid').attr('required')
                this.dom.find('#medical_affiliation_country').val('US')
                new GoogleAddressAutocomplete('medical_inst_card');
            } else {
                this.dom.find('.medical_affiliation').hide();
                this.dom.find('#medical_affiliation_name').removeClass('required is-invalid').removeAttr('required')
                this.dom.find('#medical_affiliation_address1').removeClass('required is-invalid').removeAttr('required')
                this.dom.find('#medical_affiliation_city').removeClass('required is-invalid').removeAttr('required')
                this.dom.find('#medical_affiliation_state').removeClass('required is-invalid').removeAttr('required')
                this.dom.find('#medical_affiliation_zip').removeClass('required is-invalid').removeAttr('required')
            }
        })

        this.dom.find('.file_upload').fileupload({
            maxChunkSize: 1000000000,
            url: "api/speakernominate/submit",
            dataType: 'json',
            type: 'POST',
            autoUpload: false,
            replaceFileInput: false,
            done: (e, res) => {
                this.dom.find('#submit_spk_nomination').prop('disabled', false)
                this.modal.hide();
                if(res.result.success) {
                    displayNotification({
                        title: 'Speaker nomination submitted',
                        success: true
                    });
                    app.navigate('speakernominate');
                }
                else {
                    displayNotification({
                        title: 'Speaker nomination submission failed',
                        success: false
                    });
                }
            }
        });

        this.dom.find('#speaker_id').on('click', (e) => {
            e.preventDefault();
            let speaker_id = e.target.value;
            window.open('speaker/'+speaker_id, '_blank');
            return false;
        });

        this.dom.find('#submit_spk_nomination').on('click', (e) => {
            if (e.target.className.includes('update')) {
                let updated_fields = [];

                for (let i = 0; i < app.appData.updated_field_for_notification.length; i++) {
                    let el =  this.dom.find('#'+ app.appData.updated_field_for_notification[i]);
                    if (el.val() != (el.attr('data-current') ?? '')) {
                        updated_fields.push({
                            "key": app.appData.updated_field_for_notification[i],
                            "old": el.attr('data-current'),
                            "new": el.val()
                        })
                    }
                }

                updated_fields = JSON.stringify(updated_fields);

                if (Object.keys(updated_fields).length > 0) {
                    $.ajax({
                        url     : 'api/speakernominate/update',
                        method  : 'post',
                        data: {
                            speaker_id: this.speakerData["speaker_id"],
                            speaker_name: this.dom.find('#firstname').val() + ' ' + this.dom.find('#lastname').val(),
                            updated_fields: updated_fields,
                        },
                    })
                }
            }
        })

        this.dom.find('#speaker_nominate_form').on('submit', (e) => {
            e.preventDefault();
            this.dom.find('#submit_spk_nomination').prop('disabled', true)
            let formCompleted = false;
            let evalCompleted = true;
            formCompleted = this.checkRequired();
            if(app.appData.layout.speaker_nominate.display_eval) {
                evalCompleted = this.highlight_missing_required();
            }

            let brandSub = (this.dom.find('#brand_id').val() != '' && this.dom.find('#brand_id').val() != undefined ? this.dom.find('#brand_id').val() : $('#brand').val());
            let update = this.dom.find('#submit_spk_nomination')[0].classList.contains('update') || this.editForm;

            if(formCompleted && evalCompleted) {
                this.dom.find("#speaker_nominate_form select:disabled").prop("disabled", false);
                let evalsData = [];
                let practiceSettings = [];

                this.dom.find('form[name=evaluation]').each((index, item) => {
                    let data = $(item).serializeArray();

                    let evalData = {};
                    data.forEach((data) => {
                        let name = data.name.replace('[]', '');
                        evalData[name] ??= [];
                        evalData[name].push(data.value);
                    })

                    evalsData.push(evalData);
                });

                this.dom.find("#practice_setting option:selected").each ((index, item) => {
                    const elem = $(item)
                    if (elem.val()) {
                        if (elem.text().toLowerCase() == 'other') {
                            practiceSettings.push({key:elem.val(), value: this.dom.find("#practice_setting_other").val()})
                        } else {
                            practiceSettings.push({key:elem.val(), value: elem.text()})
                        }
                    }
                })

                let formData = {};

                formData = new FormData(this.dom.find('#speaker_nominate_form')[0]);
                formData.append("brand", brandSub);
                formData.append("speaker_id", this.speakerData["speaker_id"]);
                formData.append("nominate_rdt", app.appData.curPosition.rdt);
                formData.append("practice_setting", JSON.stringify(practiceSettings))
                formData.append("sansCv", false);
                formData.append("update", update);
                formData.append("eval", JSON.stringify(evalsData));

                let submitFiles = [];
                this.dom.find('.file_upload').each((ind,el) => {
                    if($(el).prop('files').length) {
                        submitFiles.push($(el).prop('files')[0]);
                    }
                })

                if(submitFiles.length > 0) {
                    let nominationContent = {
                        files: submitFiles,
                        data: formData
                    };
                    this.dom.find('.file_upload').fileupload('send', nominationContent);
                } else {
                    let sansForm = this.dom.find('#speaker_nominate_form').serializeArray();
                    sansForm.push({name:"sansCv", value:true});
                    sansForm.push({name:"brand", value:brandSub});
                    sansForm.push({name:"speaker_id", value:this.speakerData["speaker_id"]});
                    sansForm.push({name:"nominate_rdt", value:app.appData.curPosition.rdt});
                    sansForm.push({name:"practice_setting", value:JSON.stringify(practiceSettings)})
                    sansForm.push({name:"eval", value:JSON.stringify(evalsData)});
                    sansForm.push({name:"update", value: update});
                    this.submitSanCv(sansForm);
                }
            }
            else {
                this.dom.find('#submit_spk_nomination').prop('disabled', false)
                displayNotification({
                    title: 'Missing Required Information',
                    success: false
                });
            }
        })

        this.dom.on('change', 'select', (event) => {
            this.dom.find('input#'+$(event.target).attr('id')).val($(event.target).val());
        })

        this.dom.find('#kamtll-dd-container').on('change', '#kamtlldd',(event) => {
            if($(event.target).val() === null) {
                this.dom.find("#division, #regionField, #territory").val('');
            } else {
                this.setupRDT($(event.target).val());
            }
        })

        this.dom.find('#projects-dd-container').on('change', '#projects',(event, data) => {
            let projid = $(event.target).val();
            if ($(event.target).val() == '') {
                projid = '';
            }
            let brand = '';
            let topic = '';
            if(typeof data !== 'undefined') {
                brand = data.storedBrand;
                topic = data.storedTopic;
            }
            this.setupFilteredTopics(brand, projid, topic);
        })

        this.dom.find('#restrictions').on('change', (event) => {
            if($(event.target).val() == '2'){
                this.dom.find('#resExplainCon').show();
                this.dom.find('#res_explain').addClass('is-invalid');
                this.dom.find('#res_explain').addClass('required');
            } else {
                this.dom.find('#resExplainCon').hide();
                this.dom.find('#res_explain').removeClass('is-invalid');
                this.dom.find('#res_explain').removeClass('required');
            }
        })

        this.dom.find('#belowfour').hide();

        this.dom.find('#min_score').on('change', (event) => {
            if($(event.target).val() == '1') {
                this.dom.find("#nomination_form").show();
                this.dom.find('#pre_approval_questionnaire').hide();
                this.dom.find('#scorecard').show();
                this.dom.find('#upload_scorecard').addClass('required is-invalid').attr('required');
                this.dom.find('#submit_spk_nomination').show();
            }
            else {
                this.dom.find('#pre_approval_questionnaire').show();
                this.dom.find('#nomination_form').hide();
                this.dom.find('#scorecard').hide();
                this.dom.find('#upload_scorecard').removeClass('required is-invalid').removeAttr('required').hide();
                this.dom.find('#submit_spk_nomination').hide();
            }
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }

    /**
     *
     */
    checkRequired() {
        let result = true;
        this.dom.find("#speaker_nominate_form .required").each((index, elem) => {
            if ($(elem).val()) {
                $(elem).removeClass('is-invalid')
            }
            else {
                $(elem).addClass('is-invalid')
                result = false;
            }
        })

      if ($(".nomination_types-group-checkbox").length
        && $(".nomination_types-group-checkbox:checked").length === 0) {
        $('.nomination_types-group-checkbox').closest('.form-row').addClass('is-invalid');
        result = false;
      }
        return result;
    }

    submitSanCv(formData){
        $.ajax({
            url : "api/speakernominate/submit",
            type :'POST',
            data : formData
        }).then(response => {
            if(response.success) {
                this.dom.find('#submit_spk_nomination').prop('disabled', false)
                this.modal.hide();
                displayNotification({
                    title: 'Speaker nomination submitted',
                    success: true
                });
                app.navigate('speakernominate');
            }
            else {
                displayNotification({
                    title: 'Speaker nomination submission failed',
                    success: false
                });
            }
        })
    }

    buildStateDD(stateContainer, ddName, countryCode) {
        countryCode = countryCode || 'US';
        $.ajax({
            url: 'api/states/' + countryCode,
            method: 'get'
        }).done((data) => {
            stateContainer.empty();
            this.stateDD = new Dropdown(data)
              .addClass("input-group-text form-control-sm address-field")
              .label("Select a state...");

            if (this.dom.find("#medical_affiliation_ques").val() === "1") {
              this.stateDD.addClass("required is-invalid").required("required");
            }
            this.stateDD.draw(stateContainer, "alpha2", "name", ddName);

            if (data.length == 1) {
                stateContainer.find('select').val(data[0].alpha2)
            }

            if (this.seletedState != '' && countryCode == this.speakerData.medical_affiliation_country) {
                stateContainer.find('select').val(this.seletedState);
            }
        });
    }

    setupDropdowns() {
        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass(this.fields['specialty']['required'] ? 'required' : '')
            .required(this.fields['specialty']['required'])
            .label('Select  a specialty...')
            .isModalContainer(true)
            .multiple(!!this.fields['specialty']['multiple'])
            .draw(this.dom.find("#specialty-dd-container"), 'code', 'short_name', 'specialty', [], !!this.fields['specialty']['multiple'])

        let degrees = app.format.getSortedDegrees();
        degrees = degrees.filter(degree => degree.active == '1')
        let degreeDD = new Dropdown(degrees)
        degreeDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass(this.fields['degree']['required'] ? 'required' : '')
            .required(this.fields['degree']['required'])
            .label('Select a degree...')
            .isModalContainer(true)
            .multiple(!!this.fields['degree']['multiple'])
            .draw(this.dom.find("#degree-dd-container"), 'degree_abbrv', 'degree_desc', 'degree', [], !!this.fields['degree']['multiple'])

        let stateDD = new Dropdown(app.appData.states)
        stateDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass('address-field')
            .addClass(this.fields['state']['required'] ? 'required' : '')
            .required(this.fields['state']['required'])
            .label('Select a state...')
            .isModalContainer(true)
              .draw(this.dom.find("#state-dd-container"), 'alpha2', 'name', 'state')

        let countryDD = new Dropdown(app.appData.countries)
        countryDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass('address-field')
            .addClass('country-dd')
            .label('Select a country...')
            .draw(this.dom.find("#medical-country-dd-container"), 'alpha2', 'name', 'medical_affiliation_country')
        this.dom.find("#medical-country-dd-container > select").data({
            'medical-state-dd-container': 'medical-state-dd-container',
            'state-dd-id': 'state'
        }).val('US')

        if(this.fields.region2 && this.fields.region2.data.length > 0) {
          let areaDD = new Dropdown(this.fields.region2.data);
          areaDD.addClass('input-group-text').
              addClass('form-control-sm').
              addClass('address-field').
              addClass(this.fields.region2.required ? 'required' : '').
              required(this.fields.region2.required).
              label('Select an area...').
              isModalContainer(true).
              draw(this.dom.find('#region2-dd-container'), 'keyName', 'val',
                  'region2');
        }

        if(this.fields.credentials) {
          fetch('api/speakers/credentials')
          .then(response => response.json())
          .then((data) => {
            let credentialsDD = new Dropdown(data);
            credentialsDD.addClass('input-group-text').
                addClass('form-control-sm').
                addClass(this.fields.credentials.required ? 'required' : '').
                required(this.fields.credentials.required).
                label('Select a credential...').
                multiple(true).
                isModalContainer(true).
                draw(this.dom.find('#credentials-dd-container'), 'code', 'description',
                    'credentials',[],true);
          });
        }

        if (this.fields['practice_setting'] != undefined) {
            let practiceSettingDD = new Dropdown(app.appData.practice_settings);
            practiceSettingDD.addClass('input-group-text').
                addClass('form-control-sm').
                addClass(this.fields.practice_setting.required ? 'required' : '').
                required(this.fields.practice_setting.required).
                label('Select a practice setting...').
                draw(this.dom.find('#practice_setting-dd-container'), 'key', 'name',
                    'practice_setting');
        }

        let stateLicenseDD = new Dropdown(app.appData.states)
        stateLicenseDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass(this.fields['state_of_license']['required'] ? 'required' : '')
            .required(this.fields['state_of_license']['required'])
            .isModalContainer(true)
            .label('Select a state...')
            .draw(this.dom.find("#state_of_license-dd-container"), 'alpha2', 'name', 'state_of_license')

        if(this.fields['region1'] != undefined) {
            fetch('api/speakers/regions')
            .then(response => response.json())
            .then((data) => {
                let regionDD = new Dropdown(data)
                regionDD
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .addClass(this.fields['region1']['required'] ? 'required' : '')
                .required(this.fields['region1']['required'])
                .isModalContainer(true)
                .draw(this.dom.find('#region-dd-container'), 'region', 'region', 'region1')
            })
        }

        if(this.form_type != undefined) {
            let yes = {name: "Yes", alpha2:"2"};
            let no = {name: "No", alpha2:"1"};
            let na = {name: "N/A", alpha2:"3"};
            let yn = [yes, no];
            let ynn = [yes, no, na];

            let evalsDD = new Dropdown(ynn)
            evalsDD
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .isModalContainer(true)
                .draw(this.dom.find("#prev_evals-dd-container"), 'alpha2', 'name', 'prev_evals')

            let restrictionsDD = new Dropdown(yn)
            restrictionsDD
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .isModalContainer(true)
                .draw(this.dom.find("#restrictions-dd-container"), 'alpha2', 'name', 'restrictions')

            this.dom.find('#resExplainCon').hide();

            let attestDD = new Dropdown(yn)
            attestDD
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .isModalContainer(true)
                .draw(this.dom.find("#attest-dd-container"), 'alpha2', 'name', 'attest')

            this.dom.find('#restrictions').val(this.dom.find('#restrictions').val());
            this.dom.find('#prev_evals').val(this.dom.find('#prev_evals').val());
            this.dom.find('#attest').val(this.dom.find('#attest').val());
        }

        if(this.form_type != undefined) {
            fetch('api/speakernominate/kamtlls')
                .then(response => response.json())
                .then((data) => {
                    let tllDD = new Dropdown(data)
                    tllDD
                        .addClass('input-group-text')
                        .addClass('form-control-sm')
                        .isModalContainer(true)
                        .draw(this.dom.find("#kamtll-dd-container"), 'alpha2', 'tll_name', 'kamtlldd')
                })
        }

        if(this.form_type != undefined) {
            fetch('api/speakers/languages')
                .then(response => response.json())
                .then((data) => {
                    let languageDD = new Dropdown(data)
                    languageDD
                        .addClass('input-group-text')
                        .addClass('form-control-sm')
                        .isModalContainer(true)
                        .multiple(true)
                        .draw(this.dom.find("#o_lang-dd-container"), 'alpha2', 'name', 'o_lang')
                })
        }
    }

    setupFilteredTopics(brand = '', projectid = '', saved = '') {
        let brandid = '';
        let selected = this.dom.find('#topics').val();

        if ((brand == '' || brand == null) && this.dom.find('#brand_id').val() == '') {
            brandid = $('#brand').val();
        } else if (brand == '' || brand == null) {
            brandid = this.dom.find('#brand_id').val()
        } else {
            brandid = brand
        }

        $.ajax({
            url : "api/speakernominate/filtopics",
            type :'GET',
            data : {brandid, projectid},
            success : (data) => {
                let topicsDD = new Dropdown(data);
                this.dom.find("#topics-dd-container").empty();
                topicsDD
                    .addClass('input-group-text')
                    .addClass('form-control-sm required is-invalid')
                    .required(true)
                    .all(false)
                    .multiple(true)
                    .isModalContainer(true)
                    .draw(this.dom.find("#topics-dd-container"), 'topic_id', 'topic_name_short', 'topics',[],true)

                if(saved != '' && saved !== null){
                    let valArray = saved.split(',');
                    this.dom.find('#topics').val(valArray).attr('disabled', true).removeClass('is-invalid').trigger('change');
                } else if(selected != undefined && selected.length > 0) {
                    this.dom.find('#topics').val(selected).trigger('change');
                }
            }
        })
    }

    setupFilteredProjects(brand = '', saved = '') {
        let brandid = '';
        if (brand == '') {
            brandid = $('#brand').val();
        } else {
            brandid = brand
        }

        let topics = this.speakerData['topics'];
        this.dom.find("#projects-dd-container").empty();
        $.ajax({
            url : "api/speakernominate/filprojects",
            type :'GET',
            data : {brandid},
            success : (data) => {
                let projectsDD = new Dropdown(data)
                projectsDD
                    .addClass('input-group-text')
                    .addClass('form-control-sm required is-invalid')
                    .required(true)
                    .label(' ')
                    .multiple(true)
                    .isModalContainer(true)
                    .draw(this.dom.find("#projects-dd-container"), 'project_id', 'project_name', 'projects',[],true)
                if(saved != '') {
                    let valArray = saved.split(',');
                    this.dom.find('input[name="topics"]').val(topics);
                    this.dom.find('#projects')
                        .val(valArray)
                        .attr('disabled', true)
                        .removeClass('is-invalid')
                        .trigger('change', [{storedBrand:brandid, storedTopic:topics}]);
                } else {
                    this.dom.find('#projects').trigger('change', [{storedBrand:brandid, storedTopic:topics}]);
                }
            }
        })
    }

    setupRDT(wwid) {
        $.ajax({
            url : "api/speakernominate/rdts",
            type :'GET',
            data : {wwid},
            success : (data) => {
                let territory = data['0'].territory_name;
                let district = data['0'].district_name;
                let region = data['0'].region_name;

                this.dom.find("#division").val(district);
                this.dom.find("#regionField").val(region);
                this.dom.find("#territory").val(territory);
            }
        })
    }

    setFormVals(speakerData) {
        this.speakerData = speakerData;
        this.resetFormVals();

        if(this.dom.find('#nominate_type').length){
            this.dom.find('#nominate_type').val('Primary').removeClass('is-invalid');
        }

        if (this.showMedicalFields) {
            if (speakerData.med_id) {
                this.dom.find('.medical_affiliation').show();
                this.dom.find('#medical_affiliation_ques').val(1).trigger('change');
            } else {
                this.dom.find('.medical_affiliation').hide();
            }
            this.seletedState = speakerData.medical_affiliation_state;
            new GoogleAddressAutocomplete('medical_inst_card')
        }

        if(this.form_type != undefined) {
            let brandPreApprovalReq = this.speakerData.pre_approval_required;

            if(speakerData['b_bureau'] == null){
                speakerData['b_bureau'] = $('#brand option:selected').text();
            }

            if(speakerData['brand_id'] == null){
                this.setupFilteredProjects($('#brand').val());
                brandPreApprovalReq = $("#brand :selected").data('pre_approval_required');
            } else {
                this.setupFilteredProjects(speakerData['brand_id'], speakerData['projects']);
            }
            this.checkPreApproval(brandPreApprovalReq);

            if(speakerData['kamtlldd'] == null) {
                speakerData['kamtlldd'] = app.appData.current_user['user_id'];
            }

            this.setupRDT(speakerData['kamtlldd']);

            let enableKamTll = app.appData.layout.speaker_nominate?.enable_kamtlldd_by_user_type;
            if(enableKamTll != undefined && !enableKamTll.includes(app.appData.curPosition['user_type_id'])) {
                    this.dom.find('#kamtlldd').prop('disabled', true);
            }

            if(speakerData['o_lang'] == null){
                this.dom.find('#o_lang').val('').trigger('change');
            }
        }

        Object.keys(speakerData).forEach((key) => {
            let elem = this.dom.find('#' + key);
            if(key === nomination_types_key) {
              this.setNominationTypes(speakerData);
              return;
            }

            if(key == "practice_setting") {
                this.setPracticeSetting(speakerData.practice_setting)
                this.setReadOnly(key, speakerData.practice_setting, this.dom.find('#practice_setting'))
                this.setReadOnly(key, speakerData.practice_setting, this.dom.find('#practice_setting_other')) //💩
                return
            }
            if(key === 'credentials'){
              this.setCredentials(speakerData, key, elem);
              return;
            }

            if(speakerData[key] != '' && speakerData[key] != null) {
                if(elem.length > 1){
                    if(elem.attr('multiple') != undefined && elem.attr('multiple') != false) {
                        let valArray = speakerData[key].split(',');
                        $(elem).prev().find('select').val(valArray).trigger('change');
                    } else {
                        elem.val(speakerData[key]);
                        if(elem.val() != speakerData[key]){
                            this.setSelectOptionByText(elem, speakerData[key]);
                        }

                        elem.attr("data-current", speakerData[key]);
                        elem.trigger('change');
                    }
                } else {
                    if (this.splittableFields.includes(key)) {
                        const tempArr = speakerData[key].split(",");
                        speakerData[key] = elem.attr('multiple') ? tempArr : tempArr[0]
                    }

                    elem.val(speakerData[key]).trigger("change");
                    elem.attr("data-current", speakerData[key]);
                }
            }

            this.setReadOnly(key, speakerData[key], elem)
        })

        if(this.form_type != undefined) {
            this.dom.find('#belowfour').hide();
            this.dom.find('#below_rat').removeClass('required');
            this.dom.find('#below_rat').removeClass('is-invalid');
            if(speakerData['add_com'] == '' || typeof speakerData['add_com'] == "undefined") {
                this.dom.find('#add_com').addClass('required');
                this.dom.find('#add_com').addClass('is-invalid');
            }
            if(speakerData['renominate'] == true) {
                if(speakerData['confirmed'] + speakerData['completed'] < 4) {
                    this.dom.find('#belowfour').show();
                    this.dom.find('#below_rat').addClass('required');
                    this.dom.find('#below_rat').addClass('is-invalid');
                }
                this.dom.find('#add_com').removeClass('required');
                this.dom.find('#add_com').removeClass('is-invalid');
            }
        }

        if (this.completed_program_check) {
            if (typeof speakerData['completed'] == "undefined" || ["", null, 0].includes(speakerData['completed']) || speakerData['completed'] < 4 ) {
                this.dom.find('#add_com').addClass('required');
                this.dom.find('#add_com').addClass('is-invalid');
            } else {
                this.dom.find('#add_com').removeClass('required');
                this.dom.find('#add_com').removeClass('is-invalid');
            }
        }
    }

    setReadOnly(key, value, elem) {
        if(value != '' && value != null &&
            elem.val() != '' && elem.val() != null) {
            elem.removeClass('is-invalid');
            if(this.fields[key] != undefined && (this.fields[key]['always_enable'] == undefined || this.fields[key]['always_enable'] != true)) {
                if(elem.is('select')) {
                    elem.attr('disabled', true);
                }
                else {
                    elem.attr('readonly', true).addClass('mock-disabled');
                }
            }
        }
    }

    setSelectOptionByText($sel, text){
        $sel.find('option').each(function(){
            if($(this).text() == text){
                $sel.val($(this).val());
                return true;
            }
        });

        return false;
    }

    setRequiredFields() {
        Object.keys(this.fields).forEach((fieldId) => {
            let field = this.fields[fieldId]
            if (fieldId === 'upload_cv' && this.speakerData && this.speakerData.renominate && this.fields.upload_cv.on_renominate_required === false) {
              this.dom.find('#' + fieldId).prop('required', false).removeClass('required is-invalid');
              return;
            }

            if(field['required']) {
                this.dom.find('#' + fieldId).prop('required', true).addClass('required is-invalid')
            }

            if(field['disabled'] == undefined || field['disabled'] != true) {
                this.dom.find('#' + fieldId).attr('disabled', false).attr('readonly', false);
            }
        });
    }

    resetFormVals() {
        this.dom.find('.chosen_file_name').text('');

        this.dom.find('#speaker_nominate_form').find('input,textarea').not('[name^=nomination_types]').val(null)
        this.dom.find('#speaker_nominate_form').find('select').val(null).trigger('change')
        this.dom.find('#speaker_nominate_form').find('#credentials').val(null).trigger('change');
        this.dom.find('#speaker_nominate_form').find('[name^=nomination_types]').prop('checked', false);
        this.dom.find('#speaker_nominate_form').find('input,textarea,select').removeClass('mock-disabled')
        this.setRequiredFields();
    }

    highlight_missing_required(){
        let result = true;
        $(".missing_required").removeClass("missing_required");

        $(".question_container_range").each(
            function(i) {
                if ($($(".question_container_range")[i]).find("input[type=radio]:checked").length == 0 &&
                    $($(".question_container_range")[i]).hasClass("is_mandatory")) {
                    result = false;
                    $($(".question_container_range")[i]).addClass("missing_required");
                }
            }
        );

        $(".question_container_choice").each(
            (i) => {
                let choiceContainer = $(".question_container_choice")[i];
                if ($(choiceContainer).find("input[type=radio]:checked, input[type=checkbox]:checked").length == 0 &&
                    $(choiceContainer).find("textarea").length == 0 &&
                    $(choiceContainer).hasClass("is_mandatory") &&
                    $(choiceContainer).is(":visible"))
                {
                    result = false;
                    $(choiceContainer).addClass("missing_required");
                }
            }
        );

        $(".qt_Open").each(
            function(i) {
                if (!$($(".qt_Open")[i]).find("textarea").val() &&
                    $($(".qt_Open")[i]).hasClass("is_mandatory") &&
                    $($(".qt_Open")[i]).is(":visible"))
                {
                    result = false;
                    $($(".qt_Open")[i]).addClass("missing_required");
                }
            }
        );

        return result;
    }

    fetch_evaluation_html(speaker_id, brand_id, eval_id = null, evalTab= '#eval_tabs', nomination_type_id = 0) {
        if(brand_id == null) {
            brand_id = $("#brand").val();
        }

        $.ajax({
            url	: "api/speakernominate/evaluation/html",
            type: "GET",
            data: {
                brand_id : brand_id,
                speaker_id : speaker_id,
                eval_id : eval_id ?? null
            },
            success	: (data) => {
                let evalDom = $(twig({data: eval_template}).render(data))

                evalDom.find('.qt_Header').each((i, element) => {
                    if(i == 0) {
                        $(element).find('.question_text').css({'font-weight': 'bold'});
                    }
                });

                if (speaker_id) {
                    this.populate_answers(evalDom, speaker_id, brand_id, nomination_type_id);
                }

                evalDom.on('change', '.question_choice input', (event) => {
                    if($(event.target).is(':checked') || $(event.target).attr('type') == 'checkbox') {
                        this.show_hide_dependencies(evalDom);
                    }
                })

                evalDom.find('form').append('<input type="hidden" name="nomination_type_id" value="'+nomination_type_id+'"/>')
                this.dom.find(evalTab).empty().append(evalDom);
                this.dom.find('.submit_container').hide();
                //this.dom.find('#question_set_name').hide();
            }
        });
    }

    show_hide_dependencies(tab){
        tab.find('.question_container').each((i, element) => {
            let showQuestion = true;
            if (typeof $(element).data('dependencies') != 'undefined') {
                let dependencies = $(element).data('dependencies');

                if(dependencies && dependencies.length != 0){
                    for (let d = 0; d < dependencies.length; d++) {
                        let dependency = dependencies[d];

                        if (
                            !tab.find('.question_choice input[name=' + dependency.question_id + '][value=' + dependency.choice_id + ']').is(':checked')
                            && !tab.find('.question_choice input[name="' + dependency.question_id + '[]"][value=' + dependency.choice_id + ']').is(':checked')
                        ) {
                            showQuestion = false;
                            break;
                        }
                    }
                }
            }

            if(showQuestion)
            {
                $(element).find('input,select,textarea').closest('.question_container').removeAttr('hidden')
                $(element).show();
            }
            else
            {
                $(element).find('input,select,textarea').each((i,test) => {
                    if($(test).prop('type') == 'textarea'){
                        $(test).val('');
                    } else {
                        $(test).prop('checked', false);
                    }
                })
                $(element).hide();
                $(element).find('input,select,textarea').closest('.question_container').attr('hidden', 'hidden')
            }
        })
    }

    populate_answers(evalDom, speaker_id, brand_id, nomination_type_id = 0) {
        if(brand_id == null) {
            brand_id = $("#brand").val();
        }

        if(app.appData.layout.speaker_nominate.filters1.filter(item => item.name == 'brand').length == 0) {
            brand_id = null;
        }

        evalDom.find("form[name=evaluation]").each(() => {
            let answers;

            $.ajax({
                url	: "api/speakernominate/evaluation",
                type: "GET",
                data: {
                    brand_id : brand_id,
                    nomination_type_id : nomination_type_id,
                    speaker_id : speaker_id
                },
                async	: false,
                success	: (json) => {
                    answers = json;
                }
            });

            if(answers.length <= 0) {
                return -1;
            }

            for(let i = 0; i < answers.length; i++) {
                if (answers[i].na == "1") {
                    evalDom.find('input[name="'+answers[i].question_id+'"][value=na]"').attr("checked","checked");
                    evalDom.find('input[name="'+answers[i].question_id+'[]"][value=na]"').attr("checked","checked");
                }
                else if (answers[i].choice_id > 0) {
                    evalDom.find('input[name="'+answers[i].question_id+'"][value='+answers[i].choice_id+"]").attr("checked","checked").trigger('change');
                    evalDom.find('input[name="'+answers[i].question_id+'[]"][value='+answers[i].choice_id+"]").attr("checked","checked").trigger('change');
                }
                else if (answers[i].value != null) {
                    evalDom.find('input[name="'+answers[i].question_id+'"][value='+answers[i].value+"]").attr("checked","checked");
                    evalDom.find('input[name="'+answers[i].question_id+'[]"][value='+answers[i].value+"]").attr("checked","checked");
                }
                else if (answers[i].text != null) {
                    evalDom.find('textarea[name="'+answers[i].question_id+'"]').html(answers[i].text);
                    evalDom.find('textarea[name="'+answers[i].question_id+'[]"]').html(answers[i].text);
                }
            }
        });

        this.show_hide_dependencies(evalDom);
    }

    // this bad but the way we do settingsi bad
    setPracticeSetting(settings) {
        if(settings !== "") {
            const arr = settings.split(',')
            arr.forEach((v) => {
                const elem = this.dom.find(`#practice_setting option:contains('${v}')`)
                elem.prop('selected', true)
                if( elem.length == 0) {
                    this.dom.find(`#practice_setting option:contains('Other')`).prop('selected', true)
                    this.dom.find('#practice_setting_other').val(v)
                }
            })

            this.dom.find('#practice_setting').trigger('change')
        }
    }

    setNominationTypes(speakerData) {
        $(document).ready(() => {
            this.bindNominationTypeCheckboxes(speakerData);
            if(this.showMedicalFields) {
                if (speakerData.med_id) {
                    this.dom.find('.medical_affiliation').show();
                    this.dom.find('#medical_affiliation_ques').val(1).trigger('change');
                } else {
                    this.dom.find('.medical_affiliation').hide();
                }
            }

            if (speakerData[nomination_types_key] !== '' &&
                speakerData[nomination_types_key] != null) {
                const csvString = speakerData[nomination_types_key];
                const csvArray = csvString.split(',');

                csvArray.forEach(function (value) {
                    let exists = typeof Object.entries(
                        app.appData.layout.speaker_nominate.form_fields.nomination_types.form_eval_ids
                    ).find((item) => item[1].nomination_type_id == value ) !== 'undefined';

                    if (!exists) {
                        return;
                    }

                    let checkbox = $('[name="nomination_types[]"][value='+value+']');
                    if (checkbox) {
                        checkbox.prop('checked', true);
                        checkbox.trigger('change');
                    }
                });
            }
        });
    }

    bindNominationTypeCheckboxes(speakerData) {
        if (app.appData.layout.speaker_nominate.display_eval) {
            $('.nomination_types-group-checkbox').each((index, checkbox) => {
                $(checkbox).off('change').on('change', (e) => {
                    let $checkbox = $(e.target);
                    let isChecked = $checkbox.is(':checked');
                    let eval_id = $checkbox.data('eval-id');
                    let eval_tab_id = $checkbox.data('eval_tab_id');
                    let brand_id = $checkbox.data('brand-id');
                    let nomination_type_id = $checkbox.data('nomination_type_id');
                    let speaker_id = speakerData.speaker_id;

                    if (isChecked) {
                        this.fetch_evaluation_html(speaker_id, brand_id, eval_id, eval_tab_id, nomination_type_id);
                    } else {
                        $(eval_tab_id).empty();
                    }
                });
            });
        }
    }

    setCredentials(speakerData, key, elem) {
        const dataValue = speakerData[key];
        if (dataValue && dataValue.length > 0) {
            const credentials = dataValue.split(',');
            $(elem).val(credentials).trigger('change');
        } else {
            $(elem).val([]).trigger('change');
        }
    }

    checkPreApproval(pre_approval_required) {
        this.dom.find('#nomination_form,#pre_approval_questionnaire').hide();
        if(this.editForm && pre_approval_required) {
            this.dom.find("#nomination_form, #submit_spk_nomination, #upload_scorecard").show();
            this.dom.find("#upload_scorecard")
                .prop('required',true)
                .addClass('required')
                .addClass('is-invalid')
                .closest('.form-group').show();
            if(this.speakerData['sc_file_name'] > '') {
                this.dom.find("#upload_scorecard")
                    .removeAttr('required')
                    .removeClass('required')
                    .removeClass('is-invalid')
                    .closest('div').find(".chosen_file_name").text(`Current File: ${this.speakerData['sc_file_name']}`);
            }
        } else if (pre_approval_required) {
            this.dom.find('#pre_approval_questionnaire').show();
            this.dom.find('#nomination_form, #submit_spk_nomination').hide();
            for (let field in this.preApprovalFields) {
                this.fields[field] = this.preApprovalFields[field];
                this.dom.find("#"+field)
                    .prop('required',true)
                    .addClass('required')
                    .addClass('is-invalid')
                    .show();
                this.dom.find("#"+field)
                    .closest('.form-group').show();
            }
        } else {
            this.dom.find("#nomination_form, #submit_spk_nomination").show();
            for (let field in this.preApprovalFields) {
                delete this.fields[field];
                this.dom.find("#"+field)
                    .removeAttr('required')
                    .removeClass('required')
                    .removeClass('is-invalid')
                    .closest('.form-group').hide();
            }
        }
    }

    resetExtraEvals() {
        this.dom.find("[id^='eval_tabs_']").empty()
    }
}
