let template = require('./modal.upload.twig');

export class ModalUpload
{
    constructor(data) {
        this.dom = $(twig({ data: template }).render({type: data.name, file_type: data.file_type}));
        this.data = data;

        this.dom.find('.custom-file-input').on('change', (event) => {
            this.dom.find('.custom-file-label').html($(event.target)[0]['files'][0]["name"]);
        });

        this.dom.find('#submit').on('click', (event) => {
            event.preventDefault();
            let data = new FormData(this.dom.find('#file_upload_form')[0]);
            data.append("speaker_id", this.data.speaker_id);
            data.append("file_type", this.data.file_type);
            $.ajax({
                url: "api/speakernominate/uploadfile",
                method: "POST",
                data: data,
                dataType: "json",
                processData: false,
                contentType: false
            }).then(response => {
                if(response.success) {
                    this.modal.hide();
                    displayNotification({
                        title: this.data.name+' upload successful',
                        success: true
                    });
                    // Reload the DataTable after the modal is hidden
                    this.onModalHidden();
                }
                else {
                    displayNotification({
                        title: this.data.name+' upload failed',
                        success: false
                    });
                }
            })
        });

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }

    onModalHidden() {
        // Reload the DataTable
        if (this.reloadCallback) {
            this.reloadCallback();
        }
    }

    setReloadCallback(callback) {
        this.reloadCallback = callback;
    }
}
